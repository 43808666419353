const ethers = require("ethers");
//--------------------------------
// const fs = require("fs");

const {
  db,
  collection,
  addDoc,
  getDocs,
  getDoc,
  doc,
  setDoc,
  updateDoc,
} = require("./firebase.js");
//--------------------------------
let provider = new ethers.providers.JsonRpcProvider(
  "https://mainnet.infura.io/v3/aeb2659348b24ba3a002b1ffa3233cce"
);

//-------write to trackSwap table------
async function writeToFile(data) {
  try {
    const collectionRef = collection(db, "trackSwap");

    for (const tokenAddress in data) {
      const walletData = data[tokenAddress];
      const ref = doc(collectionRef, tokenAddress);
      await setDoc(ref, walletData, { merge: true });
    }
    console.log("Data written to Firestore successfully.");
  } catch (error) {
    console.error("Error writing data to Firestore:", error);
  }

  console.log("writing to trackswap.json");
}
//--------------------------------

async function getTokenAddresses(pairAddress) {
  const pairABI = [
    "function token0() external view returns (address)",
    "function token1() external view returns (address)",
  ];
  const pairContract = new ethers.Contract(pairAddress, pairABI, provider);
  const WETH_ADDRESS = "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2";

  const token0Address = await pairContract.token0();
  const token1Address = await pairContract.token1();

  if (token0Address == WETH_ADDRESS) {
    return token1Address;
  } else {
    return token0Address;
  }
}

async function trackTransfers(txHash, contractAddress, routerAddress) {
  console.log("Getting transaction receipt...");
  const receipt = await provider.getTransactionReceipt(txHash);
  console.log("Receipt obtained.");

  let tokenTransfers = {};
  let totalTokenAmount = ethers.BigNumber.from(0);

  console.log("Processing logs...");
  for (const log of receipt.logs) {
    if (
      log.topics[0] == ethers.utils.id("Transfer(address,address,uint256)")
    ) {
      console.log("Transfer event found.");

      const normalizedFromAddress = ethers.utils.getAddress(
        "0x" + log.topics[1].slice(26)
      );

      console.log(normalizedFromAddress);
      const normalizedToAddress = ethers.utils.getAddress(
        "0x" + log.topics[2].slice(26)
      );

      console.log(normalizedToAddress);

      // Check if this is a transfer from the contract, to address will be token pair address
      if (normalizedFromAddress == ethers.utils.getAddress(contractAddress)) {
        console.log("Outgoing token transfer found.");
        const pairAddress = normalizedToAddress;
        const tokenAddress = await getTokenAddresses(pairAddress);
        const tokenAmount = ethers.BigNumber.from(log.data);
        totalTokenAmount = totalTokenAmount.add(tokenAmount);
        tokenTransfers[tokenAddress] = {
          tokenAmount: tokenAmount.toString(),
          ethAmount: "0",
        };
        console.log(`Saved token transfer: ${tokenAddress} -> ${tokenAmount}`);
      }

      // Check if this is a transfer to the router, from address will be token pair address
      if (normalizedToAddress == ethers.utils.getAddress(routerAddress)) {
        console.log("Incoming ETH transfer found.");
        const pairAddress = normalizedFromAddress;
        const tokenAddress = await getTokenAddresses(pairAddress);
        const ethAmount = ethers.BigNumber.from(log.data).toString();
        if (tokenTransfers[tokenAddress]) {
          tokenTransfers[tokenAddress].ethAmount = ethAmount;
          console.log(`Updated ETH amount: ${tokenAddress} -> ${ethAmount}`);
        }
      }
    }
  }

  // Calculate the total gas fee
  console.log(totalTokenAmount.toString());
  const totalGasFee = receipt.gasUsed.mul(receipt.effectiveGasPrice);
  const totalTokenAmountInEther = parseFloat(
    ethers.utils.formatEther(totalTokenAmount)
  );
  const totalGasFeeInEther = parseFloat(ethers.utils.formatEther(totalGasFee));

  console.log("---");
  console.log(totalGasFee.toString());
  console.log(totalGasFeeInEther);
  console.log(totalTokenAmountInEther);
  // Calculate the average gas cost per token
  let avgGasCostPerTokenInWei =
    (totalGasFeeInEther / totalTokenAmountInEther) * 10 ** 18;
  console.log(avgGasCostPerTokenInWei);
  //avgGasCostPerTokenInWei = parseInt(avgGasCostPerTokenInWei);
  //console.log(avgGasCostPerTokenInWei);

  console.log("Final transfers:");
  for (const token in tokenTransfers) {
    let ethAmount = ethers.BigNumber.from(tokenTransfers[token].ethAmount);
    console.log(`ethAmount: ${ethAmount}`);
    let tokenAmount = ethers.BigNumber.from(tokenTransfers[token].tokenAmount);
    console.log(`tokenAmount: ${tokenAmount}`);

    let ethPerToken = (ethAmount / tokenAmount) * 10 ** 18; // ETH per token
    console.log(`ethPerToken: ${ethPerToken}`);

    let afterSubtractingGas = ethPerToken - avgGasCostPerTokenInWei;
    console.log(`afterSubtractingGas: ${afterSubtractingGas}`);

    let ethPayoutPerToken = afterSubtractingGas;

    if (ethPayoutPerToken < 0) {
      ethPayoutPerToken = 0;
    } else {
      ethPayoutPerToken = afterSubtractingGas;
    }

    console.log(`ETH payout per Token: ${ethPayoutPerToken}`);
    tokenTransfers[token].ethPayoutPerToken =
      parseInt(ethPayoutPerToken).toString();
  }
  writeToFile(tokenTransfers);
  console.log(tokenTransfers);
}

//to replace with contract address--------------------------------------
const contractAddress = "0x5A3299DB369DE568Ed15F404D31812f416A48e77";
const routerAddress = "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D";

// trackTransfers(txHash);
module.exports = {
  trackTransfers,
};
