const { initializeApp } = require("firebase/app");
const {
  getFirestore,
  collection,
  addDoc,
  getDoc,
  getDocs,
  setDoc,
  doc,
  deleteDoc,
  updateDoc,
} = require("@firebase/firestore");

const firebaseConfig = {
  apiKey: "AIzaSyCNxVYH6UMenNWcZ-JPtQlInwlu7l8xhng",
  authDomain: "eggpot-6453e.firebaseapp.com",
  projectId: "eggpot-6453e",
  storageBucket: "eggpot-6453e.appspot.com",
  messagingSenderId: "306405542848",
  appId: "1:306405542848:web:79341cd046a924d8075625",
  measurementId: "G-GY62W78C9S",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

module.exports = {
  db,
  collection,
  addDoc,
  getDocs,
  doc,
  updateDoc,
  getDoc,
  setDoc,
  deleteDoc,
};
