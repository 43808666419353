import axios from "axios";
const ethers = require("ethers");
const {
  db,
  collection,
  addDoc,
  getDocs,
  getDoc,
  doc,
  setDoc,
  updateDoc,
} = require("./firebase.js");

const { Token, ChainId, Fetcher, WETH } = require("@uniswap/sdk");

const provider = new ethers.providers.JsonRpcProvider(
  "https://mainnet.infura.io/v3/aeb2659348b24ba3a002b1ffa3233cce"
); // Infura
const network = ChainId.MAINNET;
const WETH_ADDRESS = "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2";
const WETH_TOKEN = new Token(network, WETH_ADDRESS, 18);

const minABI = [
  // decimals
  {
    constant: true,
    inputs: [],
    name: "decimals",
    outputs: [{ name: "", type: "uint8" }],
    type: "function",
  },
  // totalSupply
  {
    constant: true,
    inputs: [],
    name: "totalSupply",
    outputs: [{ name: "", type: "uint256" }],
    type: "function",
  },
  // symbol
  {
    constant: true,
    inputs: [],
    name: "symbol",
    outputs: [{ name: "", type: "string" }],
    type: "function",
  },
];

async function getEthPriceInUSD() {
  const response = await axios.get(
    "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd"
  );
  return response.data.ethereum.usd;
}

async function checkLiquidity(tokenAddress, ethPriceInUSD) {
  try {
    const tokenContract = new ethers.Contract(tokenAddress, minABI, provider);
    const tokenDecimals = await tokenContract.decimals();
    const tokenSymbol = await tokenContract.symbol(); // Fetch the symbol
    const token = new Token(network, tokenAddress, tokenDecimals);
    let pair;
    try {
      pair = await Fetcher.fetchPairData(token, WETH_TOKEN, provider);
    } catch (error) {
      if (error.message.includes("pair: ")) {
        // It's the error thrown by fetchPairData, if no pair exists
        //console.log(`No liquidity pool exists for the token ${tokenSymbol} at address ${tokenAddress}`);
        return { hasLiquidity: false, pairAddress: null };
      } else if (error.message.includes("getReserves")) {
        // If it's an error in getReserves call
        //console.log(`Cannot get reserves for the token ${tokenSymbol} at address ${tokenAddress}`);
        return { hasLiquidity: false, pairAddress: null };
      } else {
        throw error; // if it's another error, just throw it and let the outer catch handle it
      }
    }

    // check which token is WETH and adjust reserves accordingly
    let reserveETH, reserveToken;
    if (pair.token0 === token) {
      reserveToken = pair.reserve0.toSignificant(6);
      reserveETH = pair.reserve1.toSignificant(6);
    } else {
      reserveToken = pair.reserve1.toSignificant(6);
      reserveETH = pair.reserve0.toSignificant(6);
    }

    const ethAmount = parseFloat(reserveETH);
    const reserveTokenFixed = parseFloat(reserveToken);
    const totalSupplyRaw = await tokenContract.totalSupply();
    const totalSupply = parseFloat(
      ethers.utils.formatUnits(totalSupplyRaw, tokenDecimals)
    );

    // get pair address
    const pairAddress = pair.liquidityToken.address;

    // calculate token price in ETH
    const tokenPriceInETH = ethAmount / reserveTokenFixed;

    // calculate token price in USD
    const tokenPriceInUSD = tokenPriceInETH * ethPriceInUSD;

    // calculate market cap
    const marketCap = totalSupply * tokenPriceInUSD;

    return {
      hasLiquidity: ethAmount >= 1,
      symbol: tokenSymbol,
      pairAddress: pairAddress,
      totalSupply: totalSupply,
      marketCap: marketCap,
      priceInUSD: tokenPriceInUSD,
    };
  } catch (error) {
    console.error(`Error checking liquidity for token ${tokenAddress}:`, error);
    return { hasLiquidity: false, pairAddress: null };
  }
}

async function checkEligibleTokens(userWalletAddress) {
  console.log("inside checkeligible tokens 1");

  //read data from transfer token table
  const transferTokensRef = doc(db, "transferTokens", userWalletAddress);
  const transferTokensSnapshot = await getDoc(transferTokensRef);
  const userTokens = transferTokensSnapshot.exists()
    ? transferTokensSnapshot.data()
    : {};
  //----------------------------------------

  console.log("transferTokenData--->", userTokens);
  if (!userTokens || Object.keys(userTokens).length === 0) {
    console.log(`No tokens found for user ${userWalletAddress}`);
    return;
  }

  const eligibleTokens = {};
  for (let tokenAddress in userTokens) {
    const result = await checkLiquidity(tokenAddress, await getEthPriceInUSD());
    console.log(
      "result true false for tokenAddress {} , liquidity {}",
      tokenAddress,
      result.hasLiquidity
    );

    if (result.hasLiquidity) {
      eligibleTokens[tokenAddress] = userTokens[tokenAddress];
    }
  }
  //-----------------------------------------------------
  let eligibleTokensData = {};
  try {
    //read from eligibletokens table
    const eligibleTokensDocRef = doc(db, "eligibleTokens", userWalletAddress);

    const eligibleTokensDoc = await getDoc(eligibleTokensDocRef);
    if (eligibleTokensDoc.exists()) {
      eligibleTokensData = eligibleTokensDoc.data();
    }
    console.log("eligibleTokensDoc--->", eligibleTokensDoc);
  } catch (error) {
    console.error("Error reading eligibleTokens data:", error);
  }

  eligibleTokensData = {
    ...eligibleTokensData,
    ...eligibleTokens,
  };
  console.log("eligibleTokensData ----> ", eligibleTokensData);
  try {
    //writing to eligible token table------------------------------------------------------
    const eligibleTokensDocRef = doc(db, "eligibleTokens", userWalletAddress);

    await setDoc(eligibleTokensDocRef, eligibleTokensData, { merge: true });
    console.log("Eligible tokens data saved to Firestore successfully.");
  } catch (error) {
    console.error("Error saving eligibleTokens data:", error);
  }
}
//----------------------------------------------------------
export default checkEligibleTokens;
