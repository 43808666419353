const ethers = require("ethers");
const {
  db,
  collection,
  addDoc,
  getDocs,
  getDoc,
  doc,
  setDoc,
  updateDoc,
} = require("./firebase.js");
async function processClaims(contract) {
  let transactions = {};
  let trackSwaps = {};
  // Load data from JSON files
  // let transactions = {
  //   "0x4AeC37ED7ea97d39591D2861e5665535c038306D": "300000749999999999998999000",
  //   "0x326C977E6efc84E512bB9C30f76E30c160eD06FB": "888888",
  // };
  // let trackSwaps = {
  //   "0x3Bb7D020e93c1B7d5a1C29fB00d50aFAb3c08781": {
  //     tokenAmount: "300060000000000000000000000",
  //     ethAmount: "89214160158112955",
  //     ethPayoutPerToken: "297242566",
  //   },
  //   "0xe5B76984de56715D1F87D16e373188AAa39e5E6C": {
  //     tokenAmount: "906600000000000000000000000",
  //     ethAmount: "122003250877232240",
  //     ethPayoutPerToken: "134493801",
  //   },
  //   "0xafA89f7085877449881131f141F477f96D02B6F0": {
  //     tokenAmount: "9900000000749999999980768352",
  //     ethAmount: "1482807590046661185",
  //     ethPayoutPerToken: "149700041",
  //   },
  // };
  try {
    //reading from transfertokens table----------------------
    const collectionRef = collection(db, "transferTokens");
    const querySnapshot = await getDocs(collectionRef);

    if (querySnapshot.empty) {
      console.log("No documents found in the collection");
      return;
    }
    querySnapshot.forEach((doc) => {
      const walletAddress = doc.id;
      const tokenData = doc.data();

      transactions[walletAddress] = tokenData;
    });

    //------------------------------------------------------------
    //reading from trackswap table----------------------
    const collectionTrackSwap = collection(db, "trackSwap");
    const querySnapshotTS = await getDocs(collectionTrackSwap);
    if (querySnapshotTS.empty) {
      console.log("No documents found in the collection");
      return;
    }
    querySnapshotTS.forEach((doc) => {
      const tokenAddress = doc.id;
      const tokenData = doc.data();

      trackSwaps[tokenAddress] = tokenData;
    });
    console.log("transactions --->", transactions);
    console.log("trackSwap---->", trackSwaps);

    //-------------------------------------------------------
  } catch (error) {
    console.error("Error reading the JSON files: ", error);
    return;
  }

  // Prepare a dictionary for claimable ETH amounts
  let claimableAmounts = {};

  for (const address in transactions) {
    // Initialize claimable amount for this address
    claimableAmounts[address] = ethers.BigNumber.from(0);
    console.log(address);

    // Iterate over each token sent by this address
    for (const tokenAddress in transactions[address]) {
      // If the token exists in trackSwaps, calculate the claimable amount
      if (trackSwaps[tokenAddress]) {
        try {
          // Calculate claimable amount for this token
          let tokenAmount = ethers.BigNumber.from(
            transactions[address][tokenAddress]
          );
          let ethPayoutPerToken = ethers.BigNumber.from(
            trackSwaps[tokenAddress].ethPayoutPerToken.toString()
          );
          // Increase claimable amount for this address
          claimableAmounts[address] = claimableAmounts[address].add(
            tokenAmount
              .mul(ethPayoutPerToken)
              .div(ethers.BigNumber.from("1000000000000000000"))
          );
        } catch (error) {
          console.error(
            `Error calculating claimable amount for token: ${tokenAddress}, address: ${address}. Error: `,
            error
          );
        }
      } else {
        console.log(`No ETH payout information for token: ${tokenAddress}`);
      }
    }
  }

  try {
    // Save claimable amounts to a JSON file
    const collectionRef = collection(db, "claimable");
    for (const [userAddress, documentData] of Object.entries(
      claimableAmounts
    )) {
      console.log("user-->", userAddress);
      console.log("claimable[user]--->", claimableAmounts[userAddress]);
      const userRef = doc(collectionRef, userAddress);
      // const documentData = claimableAmounts[userAddress];
      const stringifiedData = JSON.stringify(documentData);
      await setDoc(userRef, JSON.parse(stringifiedData), { merge: true });
    }

    console.log("writing to claimableAmount.json");
  } catch (error) {
    console.error("Error writing the claimableAmounts.json file: ", error);
    return;
  }

  console.log(claimableAmounts.toString());
  console.log(
    "Claimable amounts calculated and saved to claimableAmounts.json"
  );

  const contractABI = [
    // Replace with your contract's ABI
    "function storeClaimable(address[] calldata _addresses) external onlyOwner",
  ];
  let contractAddress = "0x5A3299DB369DE568Ed15F404D31812f416A48e77";

  let claimableArray = Object.entries(claimableAmounts);
  let chunkSize = 100; // Change this value according to your testing
  let gasLimit = 3000000; // Set an appropriate gas limit for your contract function

  for (let i = 0; i < claimableArray.length; i += chunkSize) {
    let chunk = claimableArray.slice(i, i + chunkSize);
    let addresses = chunk.map((item) => item[0]);
    let amounts = chunk.map((item) => item[1]);
    try {
      let tx = await contract.storeClaimable(addresses);
      //console.log(`Transaction hash: ${tx.hash}`);
      let receipt = await tx.wait();
      //console.log(`Transaction was mined in block ${receipt.blockNumber}`);
    } catch (error) {
      console.error(
        `Error storing claimable amount for addresses: ${addresses}. Error: `,
        error
      );
    }
  }
}

module.exports = {
  processClaims,
};
