// App.js

import React, { useEffect, useState } from "react";
import ConnectWallet from "./components/ConnectWallet";
import "./App.css";
import TokenSelector from "./components/TokenSelector";
import ControlPanel from "./components/ControlPanel";
import Web3 from "web3";
import "./TokenSelector.css";
const ethers = require("ethers");

const App = () => {
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [contract, setContract] = useState(null);
  const [isOwner, setIsOwner] = useState(false);
  const [selectedValue, setSelectedValue] = useState("0");
  const [signer, setSigner] = useState(null);
  const [provider, setProvider] = useState(null);
  const [gameContract, setGamecontract] = useState(null);
  const [tokenContract, setTokenContract] = useState(null);
  const handleSetProvider = async (provider) => {
    setProvider(provider);
    // console.log("provider--->", provider);
  };
  const handleSetSigner = async (signer) => {
    setSigner(signer);
  };
  const handleInitialiseGameAndTokenContract = async () => {
    const gameContractAddress = "0x0D2168dC6bE7D08d01C810641710491716B5F0fa"; //'0x2bdDD797FB8dFEfFcf3363481D8f2d861535549c';
    const gameContractABI = require("./components/game.json");
    const gameContract = new ethers.Contract(
      gameContractAddress,
      gameContractABI,
      signer
    );
    setGamecontract(gameContract);
    console.log("gamecontract----->", gameContract);
    const tokenContractAddress = "0xed5e50bcE863f390Ff55a4bF6F2D5069d17AC5C0";
    const tokenContractABI = [
    {
      "constant": false,
      "inputs": [
        {
          "name": "value",
          "type": "uint256"
        }
      ],
      "name": "burn",
      "outputs": [],
      "payable": false,
      "stateMutability": "nonpayable",
      "type": "function"
    }
  ]

    const tokenContract = new ethers.Contract(
      tokenContractAddress,
      tokenContractABI,
      signer
    );
    setTokenContract(tokenContract);
  };
  // ------------------------------------------------------------------------
  const [webThree, setWebThree] = useState(null);
  const [isEnable, setIsEnable] = useState(true);
  // let ownerWalletAddress;
  // const userWalletAddress = "0x5fD4944b778B27632272f467b3a04A1dA677566a"; // Replace with the user's wallet address
  const [userWalletAddress, setUserWalletAddress] = useState("");
  // const isWalletOwner = userWalletAddress === ownerWalletAddress;
  const handleWeb3 = async (webThree) => {
    setWebThree(webThree);
    console.log("web3 in app js --->", webThree);
  };
  useEffect(() => {
    checkOwnership();
  }, [userWalletAddress]);
  useEffect(() => {
    handleInitialiseGameAndTokenContract();
  }, []);

  const handleSelectedValue = (selectedValue) => {
    console.log("inside handleSelectedValue");
    setSelectedValue(selectedValue);
  };
  const checkOwnership = () => {
    const ownerWalletAddress = 0x54f9818B112A1f281C33E7C29d5d3cF53E36d2eC;
    if (userWalletAddress && ownerWalletAddress) {
      console.log("user and owner address exist");
      setIsOwner(true
        
      );
    }
    console.log("inside checkOwnerShip");
    console.log("userAddress -->", userWalletAddress);
    console.log("owner addresss--> ", ownerWalletAddress);
    console.log("isOwner---> ", isOwner);
  };

  const handleUserWalletAddress = (address) => {
    console.log(address);
    setUserWalletAddress(address);
    console.log(userWalletAddress);
  };
  const handleIsEnabled = (isEnabled) => {
    setIsEnable(isEnabled);
  };
  // -------------------------------------------------------

  const handleContract = (contract) => {
    console.log("inside handleContract");

    setContract(contract);
  };

  const handleWalletConnect = () => {
    console.log("insde handleWalletConnect");
    setIsWalletConnected(true);
    checkOwnership();
  };
  const handleWalletDisconnect = () => {
    console.log("insde handleWallet disconnect");
    setIsWalletConnected(false);
    setUserWalletAddress(null);
    setIsOwner(false);
  };

return (
  <div
      className="mainbg"
      style={{
        position: "relative",
        padding: "20px",
        height: "auto",
        width: "75%",
        marginTop: "3vw",
        marginLeft: "auto",
        marginRight: "auto",
        backgroundColor: '#ffdf52',
      }}>
    <ConnectWallet
      handleWalletConnect={handleWalletConnect}
      handleWalletDisconnect={handleWalletDisconnect}
      handleWeb3={handleWeb3}
      handleUserWalletAddress={handleUserWalletAddress}
      handleContract={handleContract}
      handleSetSigner={handleSetSigner}
      handleSetProvider={handleSetProvider}
    />
    {/* <Lottery handleContract={handleContract} webThree={webThree} /> */}

    {/* {isWalletOwner && <ControlPanel />} */}
    <TokenSelector
      userAddress={userWalletAddress}
      selectedValue={selectedValue}
      signer={signer}
      contract={contract}
      provider={provider}
      gameContract={gameContract}
      tokenContract={tokenContract}
    />
    {isOwner && (
      <ControlPanel
        handleIsEnabled={handleIsEnabled}
        userAddress={userWalletAddress}
        handleSelectedValue={handleSelectedValue}
        contract={contract}
        signer={signer}
        gameContract={gameContract}
        tokenContract={tokenContract}
      />
    )}
  </div>
);

};

export default App;
