const ethers = require("ethers");
const {
  db,
  collection,
  addDoc,
  getDocs,
  getDoc,
  doc,
  setDoc,
  updateDoc,
} = require("./firebase.js");

let participants = [];

let ticketCounts = [];
let claimableAmounts = {};

//-------------------------------------------------------------
const ETH_PER_TICKET = ethers.utils.parseEther("0.01");

async function claimEth(walletAddress, joinLuckyDraw, contract, round) {
  //-----------read participants from db----------------------------------
  try {
    const collectionRefParticipants = collection(db, "participants");
    const queryParticipants = await getDocs(collectionRefParticipants);
    if (queryParticipants.empty) {
      console.log("No documents found in the collection");
      participants = [];
    }
    queryParticipants.forEach((doc) => {
      const walletAddress = doc.id;
      participants.push(walletAddress);
    });
    console.log("participants from db --->", participants);
  } catch (err) {
    console.error("Error reading data: ", err);
  }

  //--------------------------------------------------------------
  //-----------read ticketCount from db-----------------------------------
  try {
    const collectionRefTicketCounts = collection(db, "ticketCounts");
    const queryTicketCounts = await getDocs(collectionRefTicketCounts);
    if (queryTicketCounts.empty) {
      console.log("No documents found in the collection");
      ticketCounts = [];
    }
    queryTicketCounts.forEach((doc) => {
      ticketCounts.push(doc.id);
    });
    console.log("ticketCounts from db --->", ticketCounts);
  } catch (err) {
    console.error("Error reading data: ", err);
  }
  //--------------------------------------------------------------

  //read claimable frm db--------------------------------------
  const collectionRefClaimable = collection(db, "claimable");
  const querySnapshotClaimable = await getDocs(collectionRefClaimable);

  if (querySnapshotClaimable.empty) {
    console.log("No documents found in the collection");
    return;
  }
  querySnapshotClaimable.forEach((doc) => {
    const walletAddress = doc.id;
    const data = doc.data();

    claimableAmounts[walletAddress] = data;
  });
  console.log("claimableFromDb---->", claimableAmounts);
  if (!claimableAmounts[walletAddress]) {
    console.log(`Wallet ${walletAddress} is not eligible to claim.`);
    return; // Exit the function early
  }

  console.log(claimableAmounts);
  const claimableEth = ethers.BigNumber.from(
    claimableAmounts[ethers.utils.getAddress(walletAddress)].hex
  );
  console.log(claimableEth.toString());
  let amountToClaim = claimableEth.mul(100).div(100); //fee

  if (joinLuckyDraw) {
    console.log("inside luckyDraw");
    let tickets = amountToClaim.mul(100);
    amountToClaim = claimableEth.mul(70).div(100).toString(); // They claim 60% of their ETH
    console.log(amountToClaim.toString());
    console.log(tickets.toString());

    // append to the array
    const index = participants.indexOf(walletAddress);
    console.log("index--->", index);
    if (index === -1) {
      // If the participant is not yet in the array
      // Append to the arrays
      participants.push(walletAddress);
      console.log("ticket--->", tickets);
      ticketCounts.push((parseInt(tickets / 10**18)).toString());
    } else {
      // If the participant already exists in the array
      // Update the existing ticket count
      ticketCounts[index] = (parseInt(tickets / 10**18)).toString();
    }

    //writing to particpants table-------------------------------------------
    try {
      const collectionRefParticipants = collection(db, "participants");
      for (const walletAddress of participants) {
        // await collectionRefParticipants.doc(walletAddress).set({});
        const docRef = doc(collectionRefParticipants, walletAddress);
        await setDoc(docRef, {});
      }
      console.log("Success writing participants to firestore");
    } catch (e) {
      console.log("error writing participants to firestore---->", e);
    }
    //writing to ticketCount table-------------------------------------------
    try {
      console.log("ticketCount---_>", ticketCounts);
      const collectionRefTicketCount = collection(db, "ticketCounts");
      for (const item of ticketCounts) {
        console.log("item--->", item);
        const docRef = doc(collectionRefTicketCount, item);

        console.log(item);
        await setDoc(docRef, {});
      }
      console.log(
        `User ${walletAddress} received ${tickets.toString()} lucky draw tickets.`
      );
    } catch (e) {
      console.log("error writing ticketCount to firestore---->", e);
    }
    //-------------------------------------------------------
  }
  amountToClaim = claimableEth.mul(60).div(100).toString(); //fee

  await contract.claimEth(
    ethers.utils.getAddress(walletAddress),
    amountToClaim,
    round
  );
  console.log(
    `User ${walletAddress} claimed ${ethers.utils.formatEther(
      amountToClaim
    )} ETH. We profit ${
      ethers.utils.formatEther(claimableEth) -
      ethers.utils.formatEther(amountToClaim)
    }`
  );
}

// Test with an arbitrary address and choice

module.exports = {
  claimEth,
};
