import Web3 from "web3";
import React, { useState, useEffect } from "react";
import detectEthereumProvider from "@metamask/detect-provider";
const ethers = require("ethers");


const ConnectWallet = ({
  handleWalletConnect,
  handleWalletDisconnect,
  handleWeb3,
  handleUserWalletAddress,
  handleContract,
  handleSetSigner,
  handleSetProvider,
}) => {
  const contractAddress = "0x5A3299DB369DE568Ed15F404D31812f416A48e77";
  const [isConnected, setIsConnected] = useState(false);
  const [isMainNet, setIsMainNet] = useState(false);

  let web3;
  const ABI = require("./contractABI.json");
  const connectWallet = async () => {
    const metamaskProvider = await detectEthereumProvider();
    console.log("inside connect wallet");
    if (metamaskProvider) {
      console.log("inside connect  wallet if clause");
      try {
        await metamaskProvider.request({ method: "eth_requestAccounts" });
      } catch (error) {
        console.error("User rejected wallet connection");
        return;
      }
      // Check network
      const networkId = await metamaskProvider.request({
        method: "net_version",
      });
      if (networkId !== "1") {
        window.alert("Please switch to Ethereum network.");
        return;
      }
      setIsMainNet(true);

      const provider = new ethers.providers.Web3Provider(metamaskProvider);
      const signer = provider.getSigner();
      const userAddress = await signer.getAddress();
      const contract = new ethers.Contract(contractAddress, ABI, signer);
      console.log("provider -- > ", provider);

      console.log("Ethereum provider detected!");
      web3 = new Web3(provider);
      setIsConnected(true);
      await handleWalletConnect();
      await handleWeb3(web3);
      await handleContract(contract);
      await handleSetSigner(signer);
      await handleUserWalletAddress(userAddress);
      await handleSetProvider(provider);

      console.log("user address at connect wallet", userAddress);

      metamaskProvider.on("accountsChanged", handleAccountsChanged);
      metamaskProvider.on("disconnect", handleDisconnect);
    } else {
      console.error(
        "Please install MetaMask, Trust Wallet or Coinbase Wallet!"
      );
    }
  };

  const handleAccountsChanged = (accounts) => {
    if (accounts.length === 0) {
      handleWalletDisconnect();
      setIsConnected(false);
    } else {
      handleUserWalletAddress(accounts[0]);
    }
  };

  const handleDisconnect = () => {
    handleWalletDisconnect();
    setIsConnected(false);
  };

  const disconnectWallet = () => {
    setIsConnected(false);
    handleWalletDisconnect();
  };

  return (
    <div className="connect-wallet-container">
      <div className="connected-wallet">
        {!isConnected || !isMainNet ? (
          <button
            className="connect-button"
            onClick={connectWallet}
            style={{
              fontSize: "1.2vw",
              width: "20vw",
              height: "auto",
              fontWeight: "bold",
              padding: "15px",
              border: "10px double white",
              marginTop: "1vw",
              marginRight: "1.5vw",
              backgroundColor: "#7a7a7a",
              color: "white",
              boxShadow: "0px 5px 15px rgba(0,0,0,0.3)",
              borderRadius: "80px",
              transition: "all 0.3s ease",
            }}
            onMouseDown={(e) =>
              (e.currentTarget.style.transform = "scale(0.95)")
            }
            onMouseUp={(e) => (e.currentTarget.style.transform = "")}
            onMouseLeave={(e) => (e.currentTarget.style.transform = "")}>
            Connect Wallet
          </button>
        ) : (
          <div>
            <button
              className="disconnect-button"
              onClick={disconnectWallet}
              style={{
                fontSize: "1.2vw",
                width: "20vw",
                height: "auto",
                fontWeight: "bold",
                padding: "15px",
                border: "10px double white",
                marginTop: "1vw",
                marginRight: "1.5vw",
                backgroundColor: "#57ed0c",
                color: "white",
                boxShadow: "0px 5px 15px rgba(0,0,0,0.3)",
                borderRadius: "80px",
                transition: "all 0.3s ease",
              }}
              onMouseDown={(e) =>
                (e.currentTarget.style.transform = "scale(0.95)")
              }
              onMouseUp={(e) => (e.currentTarget.style.transform = "")}
              onMouseLeave={(e) => (e.currentTarget.style.transform = "")}>
              Disconnect Wallet
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConnectWallet;
