const ethers = require("ethers");
const { Web3 } = require("web3");

async function changeStatus(account, selectedValue, contract) {
  try {
    console.log("im here");
    console.log("selectedvalue ->,", selectedValue);
    console.log("account -->", account);

    const transaction = await contract.changeStatus(selectedValue);

    console.log(transaction);
  } catch (error) {
    console.error(error);
  }
}
module.exports = {
  changeStatus,
};
