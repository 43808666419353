import React from "react";
import { ethers } from "ethers";

class ClaimPrizes extends React.Component {
  state = {
    tokenContract: null,
    contract: null,
    gameStarted: 0,
    isWinner: false,
    winnersList: [],
    downloadLink: "",
    hasFetchedWinners: false,
    hasBurned: false,
    isLoading: true,
    burnAmount: 1,
    entriesTracker: "",
    userEntries: "",
    numberOfWinners: "",
  };

  handleBurnClick = async () => {
    const { tokenContract } = this.state;
    const { account } = this.props;
    console.log("account-->", account);
    const burnAmount = ethers.utils.parseEther("1");
    try {
      console.log("Burning tokens...");
      // Call the contract method to burn tokens
      // Use ethers.js call syntax
      await tokenContract.burn(burnAmount);
      console.log("Tokens burned");
      this.setState({hasBurned: true });
    } catch (err) {
      console.error(err);
    }
  };

  componentDidMount = async () => {
    await this.initializeBlockchainData();

    this.intervalId = setInterval(this.initializeBlockchainData, 5000); // Refresh every 10 seconds
  };

  componentWillUnmount() {
    clearInterval(this.intervalId); // Clear the interval when the component unmounts
  }

  initializeBlockchainData = async () => {
    const { provider } = this.props;
    const { account } = this.props;
    if (!provider) {
      console.error(
        "Provider is not available. Please connect your wallet or install a Dapp browser."
      );
      return; // Return early if provider is not available
    }

    // setup the contract
    const gameContractAddress = "0x0D2168dC6bE7D08d01C810641710491716B5F0fa"; //'0x2bdDD797FB8dFEfFcf3363481D8f2d861535549c';
    const gameContractABI = require("./game.json");
    const signer = provider.getSigner();
    const contract = new ethers.Contract(
      gameContractAddress,
      gameContractABI,
      signer
    );
   
    const tokenContractAddress = "0xed5e50bcE863f390Ff55a4bF6F2D5069d17AC5C0";
    const tokenContractABI = [
      {
        constant: false,
        inputs: [
          {
            name: "value",
            type: "uint256",
          },
        ],
        name: "burn",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
    ];

    const tokenContract = new ethers.Contract(
      tokenContractAddress,
      tokenContractABI,
      signer
    );
    // const contract = this.props;
    // const tokenContract = this.props;
    this.setState({ contract, tokenContract });
    let gameStarted = await contract.gameStarted();
    let numberOfWinners = await contract.numberOfWinners();
    let totalEntries = await contract.totalEntries();
    totalEntries = totalEntries.toString();
    let userEntries = "?";
    if (account) {
      userEntries = await contract.getParticipantEntries(account);
    }
    gameStarted = gameStarted.toString();
    numberOfWinners = numberOfWinners.toString();
    totalEntries = totalEntries.toString();
    userEntries = userEntries.toString();
    this.setState(
      {
        gameStarted,
        numberOfWinners,
        totalEntries,
        userEntries,
        isLoading: false,
      },
      () => {
        const { hasFetchedWinners, gameStarted } = this.state;
        console.log("hasFetchedWinners:", hasFetchedWinners);
        console.log("gameStarted:", gameStarted.toString());
        console.log(this.props.account);
        // Generate winner list if game ended
        if (gameStarted == "2" && !hasFetchedWinners && this.props.account) {
          console.log("Going to fetch winners...");
          this.fetchWinnersList();
        }

        if (gameStarted !== "2") {
          console.log("fetch false");
          this.setState({ hasFetchedWinners: false });
        }
      }
    );
  };

  fetchWinnersList = async () => {
    this.setState({ isLoading: true });
    console.log("inside fetch winner list ==>");
    const { contract } = this.state;
    const { account } = this.props;
    let winnerListLength = await contract.getWinnerListLength();
    let winnersList = [];
    winnerListLength = winnerListLength.toString();
    console.log(winnerListLength);

    for (let i = 0; i < winnerListLength; i++) {
      let winnerEntry = await contract.winnerList(i);
      winnerEntry = winnerEntry.toString();
      winnersList.push(winnerEntry);

      console.log(winnersList);
    }

    // Saving to a Blob
    let gameRound = await contract.gameRound();
    gameRound = gameRound.toString();
    console.log("gameround: ", gameRound);

    const data = `Game Round: ${gameRound}\nWinners:\n${winnersList.join(
      "\n"
    )}`;
    const blob = new Blob([data], { type: "text/plain" });
    const downloadLink = URL.createObjectURL(blob);

    // Check if account is a winner

    const isWinner = await contract.winnerCheck(account).toString();
    this.setState({
      isWinner,
      winnersList,
      downloadLink,
      hasFetchedWinners: true,
      isLoading: false,
    });

    console.log("isWinner:", this.state.isWinner);
    console.log("winnersList:", this.state.winnersList);
    console.log("downloadLink:", this.state.downloadLink);
    console.log("hasFetchedWinners:", this.state.hasFetchedWinners);
  };

  claimPrizes = async () => {
    const { contract } = this.state;
    const { account } = this.props;
    try {
      console.log("Sending transaction");
      await contract.claimPrizes(account);
      console.log("Transaction sent");
    } catch (err) {
      console.error(err);
    }
  };

  // ...
  render() {
    const {
      gameStarted,
      isWinner,
      downloadLink,
      hasBurned,
      isLoading,
      burnAmount,
      totalEntries,
      userEntries,
      numberOfWinners,
      hasFetchedWinners,
    } = this.state;
    const { account } = this.props;
    
    if (gameStarted == "0") return null;
    if (isLoading) {
      return null;
    }
    if (!account) {
      return (
        <div
          className="enterBox"
          style={{
            backgroundColor: "red",
            color: "white",
            width: "auto",
            height: "auto",
            fontSize: "3vw",
            padding: "1vw",
            fontWeight: "bold",
          }}>
          Please connect your wallet to check if you won
        </div>
      );
    }

     if (!isLoading && !isWinner && account && gameStarted == 2) {
          return (
         
              <div className="enterBox" style={{ color: 'black', width: 'auto', height: 'auto', fontSize: '2vw', padding: '1vw', fontWeight: 'bold' }}>
                Sorry! You did not win. <br />
                <a href={downloadLink} download="winnerList.txt" style={{ fontSize: '1vw', color: 'black' }}>
                  LIST OF WINNERS
                </a>
                </div>
           
          );
        }


    return (
      <div>

        <div
          className="enterBox"
          style={{
            position: "relative",
            padding: "5px",
            height: "auto",
            width: "auto",
          }}>
          <div
            style={{
              textAlign: "center",
              marginTop: "10px",
              marginBottom: "20px",
            }}>
            <div className="enterBox" style={{ width: "auto", height: "auto" }}>
              <h2 style={{ fontSize: "2vw", fontWeight: "bold" }}>
                PRICE POOL ({this.state.numberOfWinners}{" "}
                {this.state.numberOfWinners === 1 ? "WINNER" : "WINNERS"}):
              </h2>
              <h2 style={{ fontSize: "1.5vw", fontWeight: "bold" }}>
                1 X SPROTO NFT + 3 X 0.1 ETH
              </h2>
              <h2 style={{ fontSize: "1.5vw", fontWeight: "bold" }}>
                0.2 ETH DONATION to ZachXBT
              </h2>
              <h2 style={{ fontSize: "1.5vw", fontWeight: "bold", marginTop:'2vw' }}>
                - - - - - - - - - - - - - - - - - - - - - - - - -
              </h2>
              <h2 style={{ fontSize: "1.5vw", fontWeight: "bold", marginTop:'2vw' }}>
                Total entries: {this.state.totalEntries}{" "}
              </h2>
              <h2 style={{ fontSize: "1.5vw", fontWeight: "bold" }}>
                -
              </h2>
              <h2 style={{ fontSize: "1.5vw", fontWeight: "bold" }}>
                Your entries: {this.state.userEntries}
              </h2>
              <h2 style={{ fontSize: "1.5vw", fontWeight: "bold", marginTop:'2vw' }}>
                - - - - - - - - - - - - - - - - - - - - - - - - -
              </h2>
            </div>

            {gameStarted === "2" && isWinner && (
              <>
                <h2 style={{ fontSize: "1.5vw", fontWeight: "bold" }}>
                  YOU WON! <br/><br/>Burn {this.state.burnAmount} $EGG to receive
                  the prizes through airdrop!
                </h2>
                <button
                  onClick={this.handleBurnClick}
                  disabled={hasBurned}
                  style={{
                    display: "block",
                    color: "black",
                    backgroundColor: hasBurned ? "#aaaaaa" : "#f27e02",
                    marginTop: "20px",
                    marginBottom: "5px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    width: "75%",
                    height: "75%",
                    fontSize: "2vw",
                    fontWeight: "bold",
                    position: "relative",
                    border: "1px",
                    boxShadow: "0px 5px 15px rgba(0,0,0,0.3)", // adds a soft shadow to give a lifting effect
                    borderRadius: "80px", // round the corners a bit for a better 3D effect
                    transition: "all 0.5s ease", // transition effect for the button when pressed
                  }}
                  onMouseDown={(e) =>
                    (e.currentTarget.style.transform = "scale(0.95)")
                  } // reduce size a bit when pressed
                  onMouseUp={(e) => (e.currentTarget.style.transform = "")} // return to original size when released
                  onMouseLeave={(e) => (e.currentTarget.style.transform = "")} // return to original size when mouse leaves
                >
                  BURN
                </button>
              </>
            )}
            {gameStarted === '2' && isWinner   && (
                <button
                  onClick={() => {

                 window.open('https://twitter.com/intent/tweet?text=I+just+swapped+my+shitcoins+for+GOLD+with+EGGPOT+by+%40eggmancoin.+Get+up+to+70%25+of+swap+value+%2B+save+on+gas+%2B+win+prizes+with+their+lucky+drawl%21+Visit+www.eggpot.eggmancoin.com+today%21%0D%0A%0D%0A%23EGGMAN+%23EGGPOT+%23EGGROLL+%23ETH+%23MEMECOIN++', '_blank');
   }}
                  style={{
                    display: 'block',
                    color: 'white',
                    backgroundColor: '#00acee',
                    margin: 'auto',
                    width: '75%',
                    height: '100%',
                    fontSize: '2vw',
                    fontWeight: 'bold',
                    position: 'relative',
                    border: '1px',
                    boxShadow: '0px 5px 15px rgba(0,0,0,0.3)', // adds a soft shadow to give a lifting effect
                    borderRadius: '80px', // round the corners a bit for a better 3D effect
                    transition: 'all 0.5s ease', // transition effect for the button when pressed
                  }}
                  onMouseDown={e => e.currentTarget.style.transform = 'scale(0.95)'} // reduce size a bit when pressed
                  onMouseUp={e => e.currentTarget.style.transform = ''} // return to original size when released
                  onMouseLeave={e => e.currentTarget.style.transform = ''} // return to original size when mouse leaves
                >
                  TWEET
                </button>
            )}
            {gameStarted === "2" && isWinner && (
              <button
                onClick={this.claimPrizes}
                disabled={!hasBurned}
                style={{
                  display: "block",
                  color: "white",
                  backgroundColor: (!hasBurned)? "#aaaaaa" : "#4caf50",
                  marginTop: "5px",
                  marginBottom: "20px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "75%",
                  height: "75%",
                  fontSize: "2vw",
                  fontWeight: "bold",
                  position: "relative",
                  border: "1px",
                  boxShadow: "0px 5px 15px rgba(0,0,0,0.3)", // adds a soft shadow to give a lifting effect
                  borderRadius: "80px", // round the corners a bit for a better 3D effect
                  transition: "all 0.5s ease", // transition effect for the button when pressed
                }}
                onMouseDown={(e) =>
                  (e.currentTarget.style.transform = "scale(0.95)")
                } // reduce size a bit when pressed
                onMouseUp={(e) => (e.currentTarget.style.transform = "")} // return to original size when released
                onMouseLeave={(e) => (e.currentTarget.style.transform = "")} // return to original size when mouse leaves
              >
                CLAIM
              </button>
            )}
            
            {downloadLink && gameStarted == "2" && (
              <a
                href={downloadLink}
                download="winnerList.txt"
                style={{ fontSize: "5px", color: "grey" }}>
                LIST OF WINNERS
              </a>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ClaimPrizes;
