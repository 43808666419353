import React, { useEffect, useState } from "react";
import axios from "axios";
import "../TokenSelector.css";
import image from "./eggman2.png";
import { tab } from "@testing-library/user-event/dist/tab";
const {
  db,
  collection,
  addDoc,
  getDocs,
  getDoc,
  doc,
  setDoc,
  updateDoc,
} = require("../Backend/firebase.js");
const ethers = require("ethers");
const transferTokens = require("../Backend/transfer.js").transferTokens;
const checkEligibleTokens = require("../Backend/checkToken0.js").default;
const ClaimPrizes = require("./luckydraw.js").default;
const claimEths = require("../Backend/claimETH.js").claimEth;

const TokenSelector = ({
  userAddress,
  selectedValue,
  signer,
  contract,
  provider,
  gameContract,
  tokenContract,
}) => {
  const [tokens, setTokens] = useState([]);
  const [activeTab, setActiveTab] = useState("Wallet");
  const [tokenAddresses, setSelectedTokens] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFetching, setisFetching] = useState(true);
  const [checkEligibleFlag, setCheckEligibleFlag] = useState(0);
  const [submited, setSubmited] = useState(false);
  const [statusFromSC, setStatusFromSC] = useState(0);
  const [viewMode, setViewMode] = useState("null");
  const [isLuckyDrawClicked, setIsLuckyDrawClicked] = useState(false);
  const [joinLuckyDraw, setJoinLuckyDraw] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [sentTokens, setSentTokens] = useState([]);
  const [isFetchBefore, setIsFetchBefore] = useState(false);
  const [claimTokens, setClaimTokens] = useState([]);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [claimableAmount, setClaimableAmount] = useState(0);
  const [round, setRound] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const [burnSuccessful, setBurnSuccessful] = useState(false); // Keep track of whether the burn was successful
  const [canClaim, setCanClaim] = useState(false); // Keep track of whether the burn was successful
  
  let status;
  const [feedbackMessage, setFeedbackMessage] = useState(null);
  
  const handleSetClaimableAmount = async () => {
    const collectionRefClaimable = collection(db, "claimable");
    const querySnapshotClaimable = await getDocs(collectionRefClaimable);
    let claimableAmounts = {};
    let amount = 0;
    if (querySnapshotClaimable.empty) {
      console.log("No documents found in the collection");
      return;
    }
    querySnapshotClaimable.forEach((doc) => {
      const userAddress = doc.id;
      const data = doc.data();

      claimableAmounts[userAddress] = data;
    });
    console.log("claimableFromDb---->", claimableAmounts);
    if (!claimableAmounts[userAddress]) {
      console.log(`Wallet ${userAddress} is not eligible to claim.`);
      amount = 0; // Exit the function early
    } else {
      console.log("amount for user ---> ", claimableAmounts[userAddress].hex);
      amount = parseInt(claimableAmounts[userAddress].hex, 16);
    }
    console.log("claimable amount for specific user --->", amount);

    // console.log("inside handleClaimableAmount, clainableamount: ");


    if (amount) {
      setCanClaim(true);
      if (joinLuckyDraw){
        setClaimableAmount(((amount*70/100) / 10**18).toFixed(3));
      }
      else if (!joinLuckyDraw){
        setClaimableAmount(((amount*60/100) / 10**18).toFixed(3));
      
      }
      console.log(
        "After setting claimableAmount, claimableAmoun --->",
        claimableAmount
      );
    }
    else{
      setClaimableAmount(0);
      setCanClaim(false);
    }
    // await fetchTokens();
  };
  const handleSetClaimTokens = async () => {
    let trackSwaps = {};
    const collectionTrackSwap = collection(db, "trackSwap");
    const querySnapshotTS = await getDocs(collectionTrackSwap);
    querySnapshotTS.forEach((doc) => {
      const tokenAddress = doc.id;
      const tokenData = doc.data();

      trackSwaps[tokenAddress] = tokenData;
    });

    const resultArray = sentTokens.map((token) => {
      const tokenAddress = token.tokenAddress;

      // Check if the tokenAddress is present in trackswaps
      if (trackSwaps.hasOwnProperty(tokenAddress)) {
        // Add the ethAmount field from trackswaps
        const ethPayoutPerToken = parseInt(
          trackSwaps[tokenAddress].ethPayoutPerToken
        );

        console.log("ethPayoutPerToken--->", ethPayoutPerToken.toString);
        // const ethAmount = ethers.BigNumber.from(token.balance).mul(
        //   ethPayoutPerToken
        // );
        const ethAmount =
          (ethPayoutPerToken * parseInt(token.balance)) / 10 ** 18;
        console.log("toke.balance--->", token.balance);
        console.log("ethAmount--->", ethAmount.toString);
        console.log("type--_>", typeof ethAmount);

        return {
          ...token,
          etherAmount: ethAmount.toFixed(3),
        };
      } else {
        // Set ethAmount field to '-'
        return {
          ...token,
          etherAmount: "-",
        };
      }
    });
    console.log("resultantARray-->", resultArray);
    setClaimTokens(resultArray);
  };

  const handleSetSentTokens = async () => {
    if (userAddress) {
      const newFilteredTokens = [];
      let data;
      const userRef = await getUserRef("transferTokens", userAddress);
      let filterDataObject = {};
      const userDoc = await getDoc(userRef); // Use getDoc here
      console.log("userDoc--->", userDoc);
      if (userDoc.exists()) {
        data = userDoc.data();
        console.log("doc exist--->data:", data);
      } else {
        return;
      }
      console.log("tokens---->", tokens);

      for (let tokenAddress in data) {
        const tokenContract = new ethers.Contract(
          tokenAddress,
          tokenContractABI,
          provider
        );
        try {
          const name = await tokenContract.name();
          const symbol = await tokenContract.symbol();
          console.log("Token Name:", name);
          console.log("Token Symbol:", symbol);

          newFilteredTokens.push({
            name: name,
            balance: (parseInt(data[tokenAddress]) / 10 ** 18).toFixed(0),
            symbol: symbol,
            tokenAddress: tokenAddress,
          });
        } catch (error) {
          console.error("Error fetching token data:", error);
        }
      }
      console.log("sentTokens--->", sentTokens);
      setSentTokens(newFilteredTokens);
    } else {
      setSentTokens([]);
    }
  };

  const handleJoinLuckyDrawChange = (event) => {
    setJoinLuckyDraw(event.target.checked);
  };
  console.log("status--->", currentStatus);

  const handleClaimButton = async () => {
    try {
      console.log(userAddress);
      console.log("lucky draw--->", joinLuckyDraw);

      await claimEths(userAddress, joinLuckyDraw, contract, round);

      console.log("claim eths success");
      await handleRemoveClaimedDataFromTransferToken();
      setClaimableAmount(0);
      setCanClaim(false);
    } catch (error) {
      console.error(error.message);
    }
  };

  async function getUserRef(collectionName, userAddress) {
    const collectionRef = collection(db, collectionName);
    const userRef = doc(collectionRef, userAddress);
    // const userDoc = await getDoc(userRef);
    return userRef;
  }
  const handleRemoveClaimedDataFromTransferToken = async () => {
    try {
      let data;

      const userRef = await getUserRef("transferTokens", userAddress);

      const userDoc = await getDoc(userRef); // Use getDoc here
      console.log("userDoc--->", userDoc);
      if (userDoc.exists()) {
        data = userDoc.data();
        console.log("doc exist--->data:", data);
      } else {
        return;
      }
      let trackSwaps = {};
      const collectionTrackSwap = collection(db, "trackSwap");
      const querySnapshotTS = await getDocs(collectionTrackSwap);
      querySnapshotTS.forEach((doc) => {
        const tokenAddress = doc.id;
        const tokenData = doc.data();

        trackSwaps[tokenAddress] = tokenData;
      });

      for (const key in data) {
        if (trackSwaps.hasOwnProperty(key)) {
          delete data[key];
        }
      }
      console.log("after removing--->", data);

      await setDoc(userRef, data);

      console.log("transferTokens collection updated successfully.");
    } catch (e) {
      console.log("error fetching/writing data from/to db");
      console.error(e);
    }

    // Updating transferTokens collection with the new data
  };
  const handleViewModeChange = (newMode) => {
    setViewMode(newMode);

    if (newMode === "lucky draw") {
      console.log("viewmode --->", viewMode);

      setIsLuckyDrawClicked(true);
    } else if (newMode === "claim") {
      handleSetClaimTokens();
      handleSetClaimableAmount();
    } else if (newMode === "pool") {
      if (!isFetchBefore) {
        fetchTokens();
        setisFetching(true);
      }
      console.log("viewmode --->", viewMode);
    }
  };

  const fetchTokens = async () => {
    if (userAddress){
      setisFetching(true);
      try {
        const response = await checkEligibleTokens(
          ethers.utils.getAddress(userAddress)
        );
        console.log("respose ---> ", response);

        let tokenArray = Array.isArray(response) ? response : [response];
        setTokens(tokenArray);
        // setTokens([]);

        setisFetching(false);
      } catch (error) {
        console.error("Failed to fetch tokens", error);
        setisFetching(false);
      }
    }
  };

  const fetchRound = async () => {
    if (userAddress){
      const currentRound = contract.currentRound();
      setRound(currentRound);
      console.log("currentRound = ", currentRound);
    }
  };

  useEffect(() => {
    fetchStatus();
    fetchTokens();
    handleSetSentTokens();
    fetchRound();
    handleSetClaimableAmount();
    handleSetClaimTokens();
    handleViewModeChange();
    const intervalId = setInterval(fetchStatus, 5000); // Fetch every 30 seconds
    
    return () => {
      // Clean up the interval on component unmount
      clearInterval(intervalId);
    };
  }, [userAddress]);

   useEffect(() => {
    handleSetClaimableAmount();
    handleSetClaimTokens();
  }, [joinLuckyDraw]);

useEffect(() => {
  if (tokens.length > 0 && !initialized) {
    const allTokenAddresses = tokens.map((token) => token.contractAddress);
    setSelectedTokens(allTokenAddresses);
    setInitialized(true);
  }
}, [tokens, initialized]);


  const handleTokenChange = (event) => {
    const value = event.target.value;
    if (!Array.isArray(tokenAddresses)) {
      console.error(
        "selectedTokens should be an array, but got:",
        tokenAddresses
      );
      return;
    }
    if (event.target.checked) {
      setSelectedTokens([...tokenAddresses, value]);
    } else {
      setSelectedTokens(tokenAddresses.filter((token) => token !== value));
    }
  };

  const fetchStatus = async () => {
    if (userAddress){
      try {
        console.log("inside fetch status");
        const contractStatus = await contract.status();
        console.log("contractStatus -->", contractStatus);
        await setCurrentStatus(contractStatus);
        console.log("contractStatus -->", contractStatus);
        console.log("currentStatus -->", currentStatus);
        if (contractStatus < 3) {
          setViewMode("pool");
        } else if (contractStatus == 3) {
          setViewMode("claim");
        } else if (contractStatus == 4) {
          setViewMode("lucky draw");
        }
        console.log("viewMode: ", viewMode);
      } catch (error) {
        console.error("Error fetching status:", error);
      }
    }
  };
  const tokenContractABI = require("./tokenAddressContractABI.json");
  const handleTabChange = async (tab) => {
    console.log("inside handleTabChange");
    setActiveTab(tab);
    // await fetchTokens();

    await handleSetSentTokens();
  };
  //mock method---------------------------------------

  //----------------------
  let statusMessage;
  if (statusFromSC === "2") {
    statusMessage = "Swapping tokens now...";
  } else if (statusFromSC === "1") {
    statusMessage = "Accepting tokens now...";
  } else if (statusFromSC === "0") {
    statusMessage = "Not Accepting Token now..";
  } else {
    statusMessage = "Different message for other values";
  }
  const renderTokenRows = (tokenList) => {
    return tokenList.map((token, index) => (
      <tr key={index} className="token-row">
        <td>{token.name}</td>
        <td>{token.symbol}</td>
        <td>{token.balance}</td>
        <td>{token.etherAmount}</td>
      </tr>
    ));
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    console.log("Selected tokens:", tokenAddresses);
    try {
      console.log("address --->", userAddress);
      console.log("array--->", tokenAddresses);
      // transferTokens(userAddress, tokenAddresses, signer);
      const response = await transferTokens(
        userAddress,
        tokenAddresses,
        signer,
        contract.connect(signer)
      );
      //
      console.log("transfertokn function finished");
      // setCheckEligibleFlag(1);
      setSubmited(!submited);
      setSelectedTokens([]);
      await fetchTokens();

      console.log("Tokens transferred successfully");

      setFeedbackMessage("Tokens transferred successfully");
      setSelectedTokens([]);
    } catch (error) {
      console.error("An error occurred:", error);
      setFeedbackMessage("An error occurred:", error);
      setTimeout(() => {
        setFeedbackMessage(null);
      }, 5000);
    } finally {
      setIsSubmitting(false);
    }
  };

  const buttonStyle = {
    display: 'block',
    color: 'white',
    backgroundColor :'#4caf50',
    width: '15vw',
    height: '40px',
    fontSize: '1.2vw',
    fontWeight: 'bold',
    marginLeft: 'auto',
    marginRight: 'auto',
    border: '1px',
    boxShadow: '0px 5px 15px rgba(0,0,0,0.3)',
    borderRadius: '80px',
    transition: 'all 0.5s ease',
  };

  const mouseEvents = {
    onMouseDown: e => e.currentTarget.style.transform = 'scale(0.95)',
    onMouseUp: e => e.currentTarget.style.transform = '',
    onMouseLeave: e => e.currentTarget.style.transform = ''
  }
const handleUnselectAll = (event) => {
  if (event.target.checked) {
    setSelectedTokens([]);
  }
  else {
    setInitialized(false);
  }
};


  const handleBurnButton = async () => {
    const burnAmount = ethers.utils.parseEther("888888");
    const tokenContractAddress = "0xed5e50bcE863f390Ff55a4bF6F2D5069d17AC5C0";
    const tokenContractABI = [
      {
        "constant": false,
        "inputs": [
          {
            "name": "value",
            "type": "uint256"
          }
        ],
        "name": "burn",
        "outputs": [],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
      }
    ]
    const tokenContract = new ethers.Contract(
          tokenContractAddress,
          tokenContractABI,
          signer
        );
    try {
      console.log("Burning tokens...");
      // Call the contract method to burn tokens
      // Use ethers.js call syntax
      await tokenContract.burn(burnAmount);
      console.log("Tokens burned");
      setBurnSuccessful(true);
    } catch (err) {
      console.error(err);
    }
  };

  const handleTweetButton = async() => {
    window.open('https://twitter.com/intent/tweet?text=I+just+swapped+my+shitcoins+for+GOLD+with+EGGPOT+by+%40eggmancoin.+Get+up+to+70%25+of+swap+value+%2B+save+on+gas+%2B+win+prizes+with+their+lucky+drawl%21+Visit+www.eggpot.eggmancoin.com+today%21%0D%0A%0D%0A%23EGGMAN+%23EGGPOT+%23EGGROLL+%23ETH+%23MEMECOIN++', '_blank');
  };


  return (
    <div
      style={{
        position: "relative",
        height: "auto",
        width: "100%",
        marginTop: "2vw",
        marginLeft: "auto",
        marginRight: "auto",
      }}>
      <div
        className="mainbanner"
        style={{
          width: "100%",
          height: "auto",
          marginRight: "auto",
          marginLeft: "auto",
        }}>
        <img
          src={image}
          alt="Descriptive alt text"
          style={{ width: "50%", height: "auto" }}
        />
      </div>
      <br />
      <br />
      {!userAddress ? (
        <div
          className="enterBox"
          style={{
            backgroundColor: "red",
            color: "white",
            width: "75%",
            height: "auto",
            fontSize: "2vw",
            fontWeight: "bold",
            padding: "1vw",
            marginLeft: "auto",
            marginRight: "auto",
          }}>
          Connect your wallet
        </div>
      ) : (
        <>
          {/* New code for the mode selector */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "20px",
            }}>
            <button
              style={{
                borderRadius: "20px 0 0 20px",
                backgroundColor: currentStatus < 3 ? "#4caf50" : "#434343",
                color: currentStatus < 3 ? "white" : "grey",
                fontWeight: "bold",
                fontSize: "1.5vw",
                borderRight: "0.2vw solid white", // Add white border on right
                padding: "10px 20px",
                cursor: "pointer",
                width: "12vw",
                border:'#fffbcf',
              }}
              onClick={() => handleViewModeChange("pool")}
              disabled={currentStatus >= 3} // Disable when currentStatus >= 3
            >
              Pool
            </button>
            <button
              style={{
                backgroundColor: currentStatus == 3 ? "#4caf50" : "#434343",
                border: "#fffbcf",
                borderRight: "0.2vw solid white", // Add white border on right
                padding: "10px 20px",
                cursor: "pointer",
                color: currentStatus == 3 ? "white" : "grey",
                fontWeight: "bold",
                fontSize: "1.5vw",
                width: "12vw",
              }}
              onClick={() => handleViewModeChange("claim")}
              disabled={currentStatus != 3} // Enable only when currentStatus == 3
            >
              Claim
            </button>
            <button
              style={{
                borderRadius: "0 20px 20px 0",
                backgroundColor: currentStatus == 4 ? "#4caf50" : "#434343",
                border: "#fffbcf",
                padding: "10px 20px",
                cursor: "pointer",
                color: currentStatus == 4 ? "white" : "grey",
                fontWeight: "bold",
                fontSize: "1.5vw",
                width: "12vw",
              }}
              onClick={() => handleViewModeChange("lucky draw")}
              disabled={currentStatus != 4} // Enable when currentStatus == 4
            >
              Lucky Draw
            </button>
          </div>

          {(viewMode == "pool" || currentStatus < 3) && (
            <>
              {/* ======== UI/UX STRUCTURE========*/}
              <div style={{ display: "flex", height: "60vh", width: "100%" }}>
  <div
    style={{
      flex: "0 0 59%",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      border: '3px dashed #FFFFFF',
      marginRight: "1%",
    }}
  >
    <div
      style={{
        flex: "0 0 85%",
        background: "#fffbcf",
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      {activeTab === "Wallet" && (
        <div className="wallet-view" style={{ overflowY: 'scroll', maxHeight: '100%', zIndex: '3' }}>
          <div className="wallet-content">
            <table className="wallet-table" style={{ width: '100%', backgroundColor: '#fffbcf' }}>
              <thead style={{ position: 'sticky', top: '0', zIndex: '2', backgroundColor: '#fffbcf' }}>
                <tr>
                  <th style={{ textAlign: 'center', fontSize: '1.5vw', position: 'sticky', top: '0', zIndex: '2', backgroundColor: '#fffbcf' }}>SELECT</th>
                  <th style={{ textAlign: 'center', fontSize: '1.5vw', position: 'sticky', top: '0', zIndex: '2', backgroundColor: '#fffbcf' }}>TOKEN NAME</th>
                  <th style={{ textAlign: 'center', fontSize: '1.5vw', position: 'sticky', top: '0', zIndex: '2', backgroundColor: '#fffbcf' }}>TOKEN BALANCE</th>
                </tr>
              </thead>
              <tbody>
                {tokens.map((token, index) => (
                  <tr key={index} className="token-row" style={{ borderRadius: '10px', backgroundColor: '#fceeac',border: '10px solid #fffbcf' }}>
                    <td style={{ textAlign: 'center', fontSize: '1.1vw' }}>
                      <input
                        type="checkbox"
                        value={token.contractAddress}
                        onChange={handleTokenChange}
                        checked={tokenAddresses.includes(token.contractAddress)}
                        className="wallet-checkbox"
                        style={{
                          textAlign: 'center',
                          marginLeft: '2.5vw',
                          transform: 'scale(1.5)',
                        }}
                      />
                    </td>
                    <td style={{ textAlign: 'center', fontSize: '1.1vw' }}>{token.name} (${token.symbol})</td>
                    <td style={{ textAlign: 'center', fontSize: '1.1vw' }}>{token.balance}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
     
                    )}
                    {activeTab === "Sent" && (
                      <div className="sent-view" style={{ overflowY: 'auto', maxHeight: '100vh', zIndex:'3' }}>
                        
                        <div className="sent-content scrollable">
                          
                          <table className="sent-table"style={{ width: '100%', display: 'block', backgroundColor: '#fffbcf' }}>
                           
                             <thead style={{ position: 'sticky', top: '0', zIndex: '2',backgroundColor: '#fffbcf'}}>
            
                              <tr>
                                <th style ={{ textAlign: 'center', fontSize:'1.5vw'}}>TOKEN NAME</th>
                                <th style ={{ textAlign: 'center', fontSize:'1.5vw'}}>SENT AMOUNT</th>
                              </tr>
                            </thead>
                            <tbody>
                              {sentTokens.map((token, index) => (
                                <tr key={index} className="token-row" style={{ borderRadius: '10px', zIndex: '1', border: '1vw solid #fffbcf', backgroundColor: '#fceeac'}}>
                                  
                                  <td style ={{ textAlign: 'center', fontSize:'1.1vw'}}>{token.name} (${token.symbol})</td>
                                  
                                  <td style ={{ textAlign: 'center', fontSize:'1.1vw'}}>{token.balance}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      flex: "0 0 15%",
                      background: "#fffbcf",
                      

                    }}>
                     <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop:"1vh",
            }}>
            <button
              style={{
                borderRadius: "20px 0 0 20px",
                backgroundColor: activeTab == "Wallet" ? "#4caf50" : "#434343",
                color: activeTab == "Wallet" ? "white" : "grey",
                fontWeight: "bold",
                fontSize: "1vw",
                borderRight: "2px solid white", // Add white border on right
                cursor: "pointer",
                width: "15vw",
                height: '3vh',
                border: '#fffbcf',

              }}
             onClick={() => handleTabChange("Wallet")}
              >
              WALLET TOKENS
            </button>
           
            <button
              style={{
                borderRadius: "0 100px 100px 0",
                backgroundColor: activeTab == "Sent" ? "#4caf50" : "#434343",
                border: "#fffbcf",
                padding: "1px 2px",
                cursor: "pointer",
                color: activeTab == "Sent" ? "white" : "grey",
                fontWeight: "bold",
                fontSize: "1vw",
                width: "15vw",
                height:'3vh',
              }}
               onClick={() => handleTabChange("Sent")}
               >
              SENT TOKENS
            </button>
            
          </div>
          {(activeTab == "Wallet" && 
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
      <input
        type="checkbox"
        onChange={handleUnselectAll}
        checked={tokenAddresses.length === 0}
        style={{
          textAlign: "center",
          transform: 'scale(1.5)',
        }}
      />
      <span style={{ marginLeft: '5px', fontSize:'1.1vw' }}>Unselect All</span>
    </div>

          )}
                    
                  </div>
                </div>
                <div
                  style={{
                    flex: "0 0 39%",
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    border: '3px dashed #FFFFFF',
                    marginLeft: "1%",


                  }}>
                  <div
                    style={{
                      flex: "0 0 100%",
                      background: "#fffbcf",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop:'auto', 
                      marginBottom: 'auto',
                    }}>
                    
                    <div className="wallet-info" style={{ textAlign: "center", marginTop: '8vh'}} >
                      <h1 style={{marginRight: '1vw', marginLeft: '1vw',fontSize: '2vw', color:'black', fontWeight:'bold', marginBottom:'2vw'}} > TURN YOUR SHITCOINS <br/>INTO GOLD </h1>
                      <h2 style={{ marginRight: '2vw', marginLeft: '2vw',textAlign:'left', fontSize: '1vw',fontWeight:'bold'}} >1) SELECT TOKENS YOU WOULD LIKE TO SEND </h2>
                      <h2 style={{marginRight: '2vw',marginLeft: '2vw',textAlign:'left',fontSize: '1vw',fontWeight:'bold'}} >2) CLICK ON THE SEND BUTTON BELOW </h2>
                      <h2 style={{marginRight: '2vw',marginLeft: '2vw',textAlign:'left',fontSize: '1vw',fontWeight:'bold'}} >3) CLICK ON THE "SENT TOKENS" BUTTON TO SEE YOUR LIST OF SENT TOKENS</h2>
                    <h2 style={{marginRight: '2vw',marginLeft: '2vw',textAlign:'left',fontSize: '1vw',fontWeight:'bold'}} >4) WAIT FOR "POOL" TO END AND "CLAIM" TO START</h2>
                   </div>
                    <button
                        type="button"
                        onClick={handleSubmit}
                        disabled={isSubmitting || isSubmitDisabled || currentStatus != 1}
                        className={`wallet-button ${isSubmitDisabled ? "disabled" : ""}`}
                        style={{
                          borderRadius: "100px",
                          backgroundColor: currentStatus == 1 ? "#4caf50" : "#434343",
                          border: "none",
                          padding: "0px",
                          cursor: "pointer",
                          color: currentStatus == 1? "white": "grey",
                          fontWeight: "bold",
                          fontSize: "1.5vw",
                          width: "15vw",
                          height:'3vh',
                          marginTop:"3vh",
                        }}
                        {...mouseEvents}
                    >
                        {isSubmitting ? "SENDING..." : "SEND"}
                    </button>
                  </div>                
                </div>
              </div>
            </>
          )}

          {(viewMode == "claim" || currentStatus == 3) && (
            <>
              {/* ======== UI/UX STRUCTURE========*/}
              <div style={{ display: "flex", height: "30vh", width: "100%" }}>
                <div
                  style={{
                    flex: "0 0 59%",
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    border: '3px dashed #FFFFFF',
                    marginRight: "1%",
                  }}>
                  <div
                    style={{
                      flex: "0 0 85%",
                      background: "#fffbcf",
                    }}>
                    <div className="claim-view" style={{ overflowY: 'auto', maxHeight: '100vh', zIndex:'3' }}>
                        
                      <div className="claim-content scrollable">
                        <table className="claim-table" style={{ width: '100%', display: 'block', backgroundColor: '#fffbcf' }}>
                           
                          <thead style={{ position: 'sticky', top: '0', zIndex: '2',backgroundColor: '#fffbcf'}}>
            
                            <tr>
                              <th style ={{ textAlign: 'center', fontSize:'1.5vw'}}>Name</th>
                              <th>Balance</th>
                              <th>ETH*</th>
                            </tr>
                          </thead> 
                          <tbody>
                            {claimTokens.map((token, index) => (
                              <tr key={index} className="token-row" style={{ borderRadius: '10px', zIndex: '1', border: '1vw solid #fffbcf', backgroundColor: '#fceeac'}}>
                                  
                                <td style ={{ textAlign: 'center', fontSize:'1.1vw'}}>{token.name} (${token.symbol})</td>
                              
                                <td style ={{ textAlign: 'center', fontSize:'1.1vw'}}>{token.balance}</td>
                                <td style ={{ textAlign: 'center', fontSize:'1.1vw'}}>{token.etherAmount} </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>

                    </div>
                    
                  </div>
                  <div
                    style={{
                      flex: "0 0 15%",
                      background: "#fffbcf",
                    }}>
                    <p style={{fontWeight:'bold', fontSize:'1vw'}}> * " - " = Token was not swapped due to lack of liquidity </p>

                    </div>
                </div>
                

                <div
                  style={{
                    flex: "0 0 39%",
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    border: '3px dashed #FFFFFF',
                    marginLeft: "1%",
                  }}>
                  <div
                    style={{
                      flex: "0 0 100%",
                      background: "#fffbcf",
                      overflow: "auto",
                    }}>
                    
                    <div className="wallet-info" style={{ textAlign: "center", marginTop: '2vh'}} >
                     
                      <h1 style={{marginRight: '1vw', marginLeft: '1vw',fontSize: '2vw', color:'black', marginBottom:'1vw',fontWeight:'bold'}} > CLAIM {claimableAmount} ETH </h1>
                      <h2 style={{ marginRight: 'auto', marginLeft: 'auto',textAlign:'center', fontSize: '1vw',fontWeight:'bold'}} >1) CHOOSE TO JOIN LUCKY DRAW OR NOT</h2>
                      <h2 style={{marginRight: 'auto',marginLeft: 'auto',textAlign:'center',fontSize: '1vw',fontWeight:'bold'}} >2) CLICK ON THE CLAIM BUTTON BELOW </h2>
                      <h2 style={{marginRight: 'auto',marginLeft: 'auto',textAlign:'center',fontSize: '1vw', marginBottom:'2vw',fontWeight:'bold'}} >3) WAIT FOR LUCKY DRAW TO START</h2>
                   </div>
                  

                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
      <input
        type="checkbox"
        onChange={handleJoinLuckyDrawChange}
        checked={joinLuckyDraw}
        style={{
          textAlign: "center",
          transform: 'scale(1.5)',
        }}
      />
      <span style={{ marginLeft: '5px', fontSize:'1.1vw', fontWeight:'bold' }}>ENTER LUCKY DRAW</span>
    </div>

                    {canClaim ? (
                      <>
                        <button
                          className="claim-button"
                          onClick={handleBurnButton}
                          disabled ={burnSuccessful}
                          style={{
                            borderRadius: "100px",
                            backgroundColor: burnSuccessful ? '#aaaaaa' : '#f27e02',
                            border: "none",
                            padding: "1px 2px",
                            cursor: "pointer",
                            color: "white",
                            fontWeight: "bold",
                            fontSize: "1.5vw",
                            width: "15vw",
                            height:'3vh',
                            marginTop:"1vw",
                          }}
                          {...mouseEvents}
                        >
                          BURN
                        </button>
                        <button
                          className="claim-button"
                          onClick={handleTweetButton}
                          style={{
                            borderRadius: "100px",
                            backgroundColor: "#00acee",
                            border: "none",
                            padding: "1px 2px",
                            cursor: "pointer",
                            color: "white",
                            fontWeight: "bold",
                            fontSize: "1.5vw",
                            width: "15vw",
                            height:'3vh',
                            marginTop:"1vw",
                          }}
                          {...mouseEvents}
                        >
                          TWEET
                        </button>
                        <button
                          className="claim-button"
                          onClick={handleClaimButton}
                          disabled ={!burnSuccessful}
                          style={{
                            borderRadius: "100px",
                            backgroundColor: burnSuccessful ? "#4caf50" : "#434343",
                            border: "none",
                            padding: "1px 2px",
                            cursor: "pointer",
                            color: burnSuccessful ? "white": "grey",
                            fontWeight: "bold",
                            fontSize: "1.5vw",
                            width: "15vw",
                            height:'3vh',
                            marginTop:"1vw",
                          }}
                          {...mouseEvents}
                        >
                          CLAIM
                        </button>
                      </>
                    ) : (
                      <div
                      className="enterBox"
                      style={{
                        backgroundColor: "red",
                        color: "white",
                        width: "75%",
                        height: "auto",
                        fontSize: "1vw",
                        fontWeight: "bold",
                        padding: "1vw",
                        marginTop: '3vw',
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}>
                      NOTHING TO CLAIM
                    </div>
                    )}



                  </div>

                  
                </div>
              </div>
            </>
          )}

          {(viewMode == "lucky draw" || currentStatus == 4) && (
            <>
              
              <ClaimPrizes
                account={userAddress}
                provider={provider}
                contract={gameContract}
                tokenContract={tokenContract}
                
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default TokenSelector;
