import axios from "axios";

const ethers = require("ethers");

const moralisApiKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6ImNhY2M5MDMwLWY3Y2ItNDkxMy05MzgwLWQxNzA1M2Q5ZTZkZCIsIm9yZ0lkIjoiMzQzOTUyIiwidXNlcklkIjoiMzUzNTgyIiwidHlwZUlkIjoiYTMxOGE4OWYtNDY1NC00NjIxLWE1MWMtOTQ2MjE1MWE0YjdhIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE2ODY5OTg4ODYsImV4cCI6NDg0Mjc1ODg4Nn0.etnF-QKi7Y4m4lL1nX7NVTALW7w1EWbEfebBj4N2k8s";
async function checkEligibleTokens(userAddress) {
  console.time("Execution Time");

  const response = await axios.get(
    `https://deep-index.moralis.io/api/v2/${userAddress}/erc20?chain=eth`,
    {
      headers: {
        "X-API-Key": moralisApiKey,
      },
    }
  );

  const tokens = {};
  for (let tokenInfo of response.data) {
    const contractAddress = ethers.utils.getAddress(tokenInfo.token_address);
    const tokenName = tokenInfo.name;
    const token = tokenInfo.balance;
    const tokenBalance = (parseInt(token) / 10 ** 18).toFixed(0);
    const symbol = tokenInfo.symbol;
    const tokenAddress = tokenInfo.token_address;
    tokens[contractAddress] = {
      name: tokenName,
      balance: tokenBalance,
      symbol: symbol,
      tokenAddress: tokenAddress,
    };
  }

  console.log(tokens);
  const totalTokens = Object.keys(tokens).length;
  console.log(`Total tokens: ${totalTokens}`);
  console.timeEnd("Execution Time");

  return Object.keys(tokens).map((contractAddress) => ({
    contractAddress,
    ...tokens[contractAddress],
  }));
}

// checkEligibleTokens();
export default checkEligibleTokens;
