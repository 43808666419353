import React, { useState, useEffect } from "react";
import "../ControlPanel.css";
import axios from "axios";
import { ethers } from "ethers";
import TokenSelector from './TokenSelector';
const changeStatus = require("../Backend/changeStatus.js").changeStatus;
const sendListofTokenAddress = require("../Backend/sendListOfTokenAddress.js");
const trackTransfers = require("../Backend/trackSwaps.js").trackTransfers;
const processClaims = require("../Backend/claimableAmounts.js").processClaims;
const {
  db,
  collection,
  addDoc,
  getDocs,
  getDoc,
  doc,
  deleteDoc,
  setDoc,
  updateDoc,
} = require("../Backend/firebase.js");

const ControlPanel = ({
  handleIsEnabled,
  userAddress,
  handleSelectedValue,
  contract,
  signer,
}) => {
  const [userWalletAddress, setUserWalletAddress] = useState("");
  const [toggleMessage, setToggleMessage] = useState(null);
  const [isEnable, setIsEnable] = useState(true);
  const [selectedValue, setSelectedValue] = useState("0");
  const [txHash, setTxHash] = useState("");
  const [joinLuckyDraw, setJoinLuckyDraw] = useState(false);
  const [message, setMessage] = useState(null);
  const [swapStage, setSwapStage] = useState(0);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [gameStarted, setGameStarted] = useState(null);
  
  const LDContractAddress = '0x0D2168dC6bE7D08d01C810641710491716B5F0fa';//'0x2bdDD797FB8dFEfFcf3363481D8f2d861535549c';
  const LDContractABI = require('./game.json');
  const LDcontract = new ethers.Contract(LDContractAddress, LDContractABI, signer);
   
  const fetchStatus = async () => {
  try {
    console.log('inside fetch status');
    const contractStatus = await contract.status();
    setCurrentStatus(contractStatus);
  } catch (error) {
    console.error('Error fetching status:', error);
  }
};

const fetchGameStarted = async () => {
  try {
    const gameStarted = await LDcontract.gameStarted();
    setGameStarted(gameStarted.toString());
  } catch (error) {
    console.error('Error fetching status:', error);
  }
};

  useEffect(() => {
    console.log('inside user effect');
  fetchStatus();
  fetchGameStarted();

  const intervalId = setInterval(() => {
      fetchStatus();
      fetchGameStarted();
    }, 5000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
}, []); // Empty array means this effect runs once on component mount.

   useEffect(() => {
  fetchStatus();
  fetchGameStarted();
}, [selectedValue]); // Empty array means this effect runs once on component mount.


  const toggleRound = () => {
    setIsEnable(!isEnable);
    handleIsEnabled(!isEnable);
  };

  const handleTxHash = (event) => {
    setTxHash(event.target.value);
    console.log(txHash);
  };

  const buttonStyle = {
    display: 'block',
    color: 'white',
    backgroundColor :'#4caf50',
    width: '15vw',
    height: '40px',
    fontSize: '1.2vw',
    fontWeight: 'bold',
    marginLeft: '1vw',
    border: '1px',
    boxShadow: '0px 5px 15px rgba(0,0,0,0.3)',
    borderRadius: '80px',
    transition: 'all 0.5s ease',
  };

  const mouseEvents = {
    onMouseDown: e => e.currentTarget.style.transform = 'scale(0.95)',
    onMouseUp: e => e.currentTarget.style.transform = '',
    onMouseLeave: e => e.currentTarget.style.transform = ''
  }

  // Function to compare the user's wallet address with the owner's wallet address
  const handleChangeInDropDown = (event) => {
    setSelectedValue(event.target.value);
    console.log("selectd value -->", selectedValue);
  };

  const submitChangeStatus = async () => {
    try {
      console.log(userAddress);

      await changeStatus(userAddress, selectedValue, contract);
      handleSelectedValue(selectedValue);
      // console.log(response.data.message);
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleSendListOfToken = async () => {
    try {
      console.log("inside handleSendListOfToken");

      await sendListofTokenAddress(contract);
      // console.log(response.data.message);
      setSwapStage(1);

    } catch (error) {
      console.error(error.message);
    }
      };

  const handleBulkSwap = async () => {
    try {
      console.log("handleBulkSwap");

      const txSwap = await contract.swapTokensForEthBulk();
      await txSwap.wait();

    setSwapStage(2);
      // console.log(response.data.message);
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleTrackSwaps = async () => {
    try {
      console.log(userAddress);

      const contractAddress = "0x5A3299DB369DE568Ed15F404D31812f416A48e77";
      const routerAddress = "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D";
      await trackTransfers(txHash, contractAddress, routerAddress);
      console.log("handleSelectedValue prop: ", handleSelectedValue);

      setSwapStage(3);
      handleSelectedValue(selectedValue);
      // console.log(response.data.message);
    } catch (error) {
      console.error(error.message)
    }
  };

  const handleClaimable = async () => {
    try {
      console.log("inside handleClaimable, userAddress-->", userWalletAddress);

      await processClaims(contract);
      setSwapStage(4);
    } catch (error) {
      console.error(error.message);
    }
   
      
    
  };

 const handleNumberOfWinners = (event) => {
    setNumberOfWinners(event.target.value);
    console.log(numberOfWinners);
  };

  const [numberOfWinners, setNumberOfWinners] = useState("");
  

  const handleGameSetup = async () => {
    try {
      console.log("handleGameSetup");

     await LDcontract.gameSetup(numberOfWinners);

      // console.log(response.data.message);
    } catch (error) {
      console.error(error.message);
    }
  };

 const handleETHPerWinner = (event) => {
  console.log(event.target.value);
    setETHPerWinner(event.target.value);
    console.log(ETHPerWinner);
  };

  const [ETHPerWinner, setETHPerWinner] = useState("");
  
  

  const handleSetETHRewards = async () => {
    try {
      console.log("handleSetETHRewards");

       const ETHPerWinnerWei = ethers.utils.parseEther(ETHPerWinner);

      await LDcontract.setRewardsETHPerWinner(ETHPerWinnerWei);

      // console.log(response.data.message);
    } catch (error) {
      console.error(error.message);
    }
  };


   const handleNFTADD = (event) => {
    setNFTADD(event.target.value);
    console.log(NFTADD);
  };

  const [NFTADD, setNFTADD] = useState("");

  const handleNFTID = (event) => {
    setNFTID(event.target.value);
    console.log(NFTID);
  };

  const [NFTID, setNFTID] = useState("");
  
  
  const handleSetNFTRewards = async () => {
    try {
      console.log("handleSetNFTRewards");

      const parsedNFTID = JSON.parse(NFTID);
      const parsedNFTADD = JSON.parse(NFTADD);

      await LDcontract.setRewardsNFT(parsedNFTADD,parsedNFTID);

   // console.log(response.data.message);
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleRequestRandomNumber= async () => {
    try {
      console.log("handleRequestRandomNumber");

      await LDcontract.requestRandomNumber();

      // console.log(response.data.message);
    } catch (error) {
      console.error(error.message);
    }
  };

  const handlePickWinners = async () => {
    try {
      console.log("handlePickWinners");

      await LDcontract.pickWinners();

       // console.log(response.data.message);
    } catch (error) {
      console.error(error.message);
    }
  };

  const deleteAllDocumentsFromCollections = async (collectionNames) => {
    for (let collectionName of collectionNames) {
      const collectionRef = collection(db, collectionName);
      const query = await getDocs(collectionRef);
      if (query.empty) {
        console.log("No documents found in the collection");
        continue;
      }
      for (const document of query.docs) {
        await deleteDoc(doc(db, collectionName, document.id));
      }

      console.log(
        `All documents in collection ${collectionName} have been deleted.`
      );
    }
  };

  const handleReset = async () => {
    try {
      const collectionsToDelete = [
        "participants",
        "eligibleTokens",
        "ticketCounts",
        "trackSwap",
        "claimable",
      ];
      deleteAllDocumentsFromCollections(collectionsToDelete);
      setSwapStage(0);
      setCurrentStatus(null);
     
      await LDcontract.resetGame();

      console.log("handleReset");

      //await contract.swapTokensForEthBulk();

      // console.log(response.data.message);
    } catch (error) {
      console.error(error.message);
    }
  };

 

  const autoResize = (event) => {
    event.target.style.height = 'inherit';
    event.target.style.height = `${event.target.scrollHeight}px`;
  };

//======================================//
  return (
    <div className="cpbg" style={{ position: 'relative', padding: '20px',height: 'auto', width: '90%', marginLeft: 'auto', marginRight:'auto', marginTop:'10vw'}}>
     <div >
      <h1 style ={{fontSize: '2vw', border:"1vw", backgroundColor:"red", width:"80%", height:"auto", marginRight:"auto", marginLeft:"auto", color:"white"}}> CONTROL PANEL </h1>
     
      </div>

      <div style={{display: 'flex', alignItems: 'center', fontSize: '1.5vw', fontWeight:'bold', marginLeft:'auto', marginRight:'auto', justifyContent: 'center', marginBottom: '2vw'}}>
       
    <label>
        CURRENT DAPP STATUS: {currentStatus} | SET ==>&nbsp;&nbsp;
        <select 
            value={selectedValue} 
            onChange={handleChangeInDropDown}
            style={{
                height: '3vw',
                width: 'auto',
                fontSize: '1vw',

            }}
        >
            <option value="0">NOT STARTED</option>
            <option value="1">POOL MODE</option>
            <option value="2">SWAP MODE</option>
            <option value="3">CLAIM MODE</option>
            <option value="4">LUCKY DRAW</option>
            <option value="5">RESET</option>
        </select>
    </label>
    <br / >
    <br / >
    <button  
        onClick={submitChangeStatus}
        style={{
            display: 'block',
            color: 'white',
            backgroundColor :'#4caf50',
           
            width: '5vw',
            height: '40px',
            fontSize: '1.2vw',
            fontWeight: 'bold',
            marginLeft: '1vw',
            border: '1px',
            boxShadow: '0px 5px 15px rgba(0,0,0,0.3)',
            borderRadius: '80px',
            transition: 'all 0.5s ease',
        }}
        onMouseDown={e => e.currentTarget.style.transform = 'scale(0.95)'}
        onMouseUp={e => e.currentTarget.style.transform = ''}
        onMouseLeave={e => e.currentTarget.style.transform = ''}
    >
        SET
    </button>
</div>
 <h2 style={{display: 'flex', marginLeft: '1%', fontSize: '1.5vw'}}>1. DAPP STATUS(2) => SWAPPING OPERATIONS</h2>
 <div className="cpbox" style={{ position: 'relative', paddingTop: '1.5vw',height: 'auto', width: 'auto', marginLeft: 'auto', marginRight:'auto'}}>
    <div style={{display: 'flex', alignItems: 'center', fontSize: '1.5vw', fontWeight:'bold', marginLeft:'auto', marginRight:'auto', justifyContent: 'center', marginBottom: '1.5vw'}}>
    
      <div>
        <button
          onClick={handleSendListOfToken}
          //disabled={swapStage !== 0 || currentStatus != 2}
          style={{...buttonStyle, marginBottom: '2.2vw',padding:"0.5vw",height:"3vw", width:"15vw",fontSize: "1vw",backgroundColor: (swapStage === 0 && currentStatus === 2)? '#4caf50' : 'grey' }}
          {...mouseEvents}
        >
          SEND LIST OF TOKENS
        </button>
      </div>

      <div>
        <button
          onClick={handleBulkSwap}
          //disabled={swapStage !== 1}
          style={{ ...buttonStyle, marginBottom: '2.2vw',padding:"0.5vw",height:"3vw", width:"15vw",fontSize: "1vw",backgroundColor: swapStage === 1 ? '#4caf50' : 'grey' }}
        {...mouseEvents}
      >
          BULK SWAP
        </button>
      </div>

      <div>
        <button
          onClick={handleTrackSwaps}
          //disabled={swapStage !== 2}
          style={{ ...buttonStyle, marginBottom: '0.5vw', padding:"0.5vw",height:"3vw", width:"15vw",fontSize: "1vw",backgroundColor: swapStage === 2 ? '#4caf50' : 'grey'}}
        {...mouseEvents}
      >
          TRACK SWAP
        </button>
        <input
          type="text"
          placeholder="Enter txHash"
          value={txHash}
          onChange={handleTxHash}
         // disabled={swapStage !==2} 
          style={{
            fontSize: "1vw",
            width: '80%',

          }}
        />
      </div>

      <div>
        <button
          onClick={handleClaimable}
          //disabled={swapStage !== 3}
          style={{ ...buttonStyle, marginBottom: '2.2vw',padding:"0.5vw",height:"3vw", width:"15vw",fontSize: "1vw",backgroundColor: swapStage === 3 ? '#4caf50' : 'grey'}}
        {...mouseEvents}
      >
          CLAIMABLE AMOUNT
        </button>
      </div>
</div>
      </div> <br />
      <h2 style={{display: 'flex', marginLeft: '1%', fontSize: '1.5vw'}}>2. DAPP STATUS(4) => LUCKY DRAW OPERATIONS</h2>
      <h2 style={{display: 'flex', marginLeft: '1%', fontSize: '1.3vw'}}>CURRENT GAME STATUS: {gameStarted} </h2> 
    <div className="cpbox" style={{ position: 'relative', paddingTop: '1.5vw',height: 'auto', width: 'auto', marginLeft: 'auto', marginRight:'auto'}}>
    <div style={{display: 'flex', alignItems: 'center', fontSize: '1.5vw', fontWeight:'bold', marginLeft:'auto', marginRight:'auto', justifyContent: 'center', marginBottom: '1.5vw'}}>
    
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: '1.5vw', fontWeight:'bold', marginLeft:'auto', marginRight:'auto', justifyContent: 'space-around', height: '100%'}}>
     <button
         onClick={handleGameSetup}
          disabled={currentStatus != 4}
          style={{...buttonStyle, marginBottom: '0.5vw',padding:"0.5vw",height:"4vw", width:"10vw",fontSize: "1vw",backgroundColor: currentStatus === 4 ? '#4caf50' : 'grey' }}
          {...mouseEvents}
        >
          GAME SETUP
        </button>
        <input
          type="text"
          placeholder="# OF WINNERS"
          value={numberOfWinners}
          onChange={handleNumberOfWinners}
          //disabled={currentStatus != 4} // disable until BULK SWAP is done
          style={{
            fontSize: "1vw",
            width: 'auto',
            marginBottom:"2vw",

          }}
        />
      </div>

      
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: '1.5vw', fontWeight:'bold', marginLeft:'auto', marginRight:'auto', justifyContent: 'space-around', height: '100%'}}>
      <button
          onClick={handleSetNFTRewards}
         // disabled={currentStatus != 4}
          style={{ ...buttonStyle, marginTop: '1.8vw', marginBottom: '0.5vw',padding:"0.5vw",height:"4vw", width:"10vw",fontSize: "1vw",backgroundColor: currentStatus === 4 ? '#4caf50' : 'grey' }}
        {...mouseEvents}
      >
          SET REWARDS (NFT)
        </button>
        <textarea
  placeholder="[NFT ADD ARRAY]"
  value={NFTADD}
  onChange={(e) => { handleNFTADD(e); autoResize(e); }}
  //disabled={currentStatus != 4} // disable until BULK SWAP is done
  style={{
    fontSize: "1vw",
    width: 'auto',
    display: 'block',
    overflow: 'hidden' // To avoid scrollbars
  }}
/>

<textarea
  placeholder="[NFT ID ARRAY]"
  value={NFTID}
  onChange={(e) => { handleNFTID(e); autoResize(e); }}
  //disabled={currentStatus != 4} // disable until BULK SWAP is done
  style={{
    fontSize: "1vw",
    width: 'auto',
    overflow: 'hidden' // To avoid scrollbars
  }}
/>

        </div>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: '1.5vw', fontWeight:'bold', marginLeft:'auto', marginRight:'auto', justifyContent: 'space-around', height: '100%'}}>
     <button
          onClick={handleSetETHRewards}
         // disabled={currentStatus != 4}
          style={{ ...buttonStyle, marginBottom: '0.5vw',padding:"0.5vw",height:"4vw", width:"10vw",fontSize: "1vw",backgroundColor: currentStatus === 4 ? '#4caf50' : 'grey' }}
        {...mouseEvents}
      >
          SET REWARDS (ETH)
        </button>
        <input
          type="text"
          placeholder="ETH PER WINNER"
          value={ETHPerWinner}
          onChange={handleETHPerWinner}
          //disabled={currentStatus != 4} // disable until BULK SWAP is done
          style={{
            fontSize: "1vw",
            width: 'auto',
            marginBottom: "2vw",
          }}
        />
        </div>
     
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: '1.5vw', fontWeight:'bold', marginLeft:'auto', marginRight:'auto', justifyContent: 'space-around', height: '100%'}}>
      <button
          onClick={handleRequestRandomNumber}
          //disabled={currentStatus != 4}
          style={{ ...buttonStyle, padding:"0.5vw",marginBottom: '4vw', height:"4vw", width:"12vw",fontSize: "1vw",backgroundColor: currentStatus === 4 ? '#4caf50' : 'grey' }}
        {...mouseEvents}
      >
          GENERATE RANDOM NUMBER
        </button>
      </div>

      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: '1.5vw', fontWeight:'bold', marginLeft:'auto', marginRight:'auto', justifyContent: 'space-around', height: '100%'}}>
      <button
          onClick={handlePickWinners}
          //disabled={currentStatus != 4}
          style={{ ...buttonStyle, marginBottom: '4vw',padding:"0.5vw",height:"4vw", width:"10vw",fontSize: "1vw",backgroundColor: currentStatus === 4 ? '#4caf50' : 'grey' }}
        {...mouseEvents}
      >
          PICK WINNERS
        </button>
      </div>
</div>
      </div>
      <h2 style={{display: 'flex', marginLeft: '1%', fontSize: '1.5vw'}}> <br />3. RESET DAPP</h2> 
 <div className="cpbox" style={{ position: 'relative', paddingTop: '1.5vw',height: 'auto', width: 'auto', marginLeft: 'auto', marginRight:'auto'}}>
    <div style={{display: 'flex', alignItems: 'center', fontSize: '1.5vw', fontWeight:'bold', marginLeft:'auto', marginRight:'auto', justifyContent: 'center', marginBottom: '1.5vw'}}>
    
      <div>
        <button
              onClick={handleReset}
              // disabled={currentStatus > 0}
              style={{
                ...buttonStyle,
                padding: "0.5vw",
                height: "3vw",
                width: "60vw",
                fontSize: "1/5vw",
                backgroundColor: "#4caf50",
              }}
              {...mouseEvents}>
              RESET DAPP
            </button>
      </div>

     

      

</div>

      </div>
      
    </div>
    
  );
};

export default ControlPanel;
