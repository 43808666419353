const ethers = require("ethers");
const {
  db,
  collection,
  addDoc,
  getDocs,
  getDoc,
  doc,
  setDoc,
  updateDoc,
} = require("./firebase.js");

async function sendListofTokenAddress(contract) {
  let data = {};

  try {
    const collectionRef = collection(db, "eligibleTokens");
    const querySnapshot = await getDocs(collectionRef);

    if (querySnapshot.empty) {
      console.log("No documents found in the collection");
      return;
    }
    querySnapshot.forEach((doc) => {
      const walletAddress = doc.id;
      const tokenData = doc.data();

      data[walletAddress] = tokenData;
    });

    console.log("Retrieved data from Firestore:", data);

    let tokenAddresses = [];
    for (let walletAddress in data) {
      for (let tokenAddress in data[walletAddress]) {
        if (!tokenAddresses.includes(tokenAddress)) {
          tokenAddresses.push(tokenAddress);
        }
      }
    }

    console.log(tokenAddresses);
    const tx = await contract.receiveTokenAddresses(tokenAddresses);
    await tx.wait();
    // const receipt = await tx.wait();
    console.log("this is tx--->", tx);
  } catch (error) {
    console.error("Error reading data from Firestore:", error);
  }
}

module.exports = sendListofTokenAddress;
